import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import ProductData from "../../components/productData";
import ProductComponent from "../../components/productComponent";
import productImage from "../../assets/images/proPic.png";

const OurProducts = () => {
  const classes = useStyles();
  /////////////////////////
  const productTitle = "Lorem ipsum dolor ";
  const productDescription =
    "Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam. Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt gravida aliquam.";
  //////////////////////////
  return (
    <Box className={classes.productPage}>
      <Box className={classes.productBackground}>
        <Box className={classes.title}>
          <h1 className={classes.infoTitle}>Our Products</h1>
          <Typography className={classes.paragraph}>
            DesignPro: A comprehensive tool for professional graphic designers,
            offering advanced features for creating stunning visuals and
            intricate designs. EasyCreate: An intuitive design software for
            beginners and non-designers, providing user-friendly templates and
            tools to create beautiful graphics effortlessly.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.productIntro}>
        <h1>Our Products</h1>
        <Typography>
          Our product lineup is designed to meet the diverse needs of our
          customers, blending innovation, quality, and user-centric design. Each
          product is crafted with attention to detail and a commitment to
          excellence, ensuring that we deliver the best solutions for your
          needs.
        </Typography>
      </Box>
      <Box sx={{ padding: "2rem 5rem" }}>
        <ProductData
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
      </Box>
      <Box
        sx={{
          padding: "2rem 5rem",
          display: "flex",
          flexDirection: "column",
          gap: "6rem",
        }}
      >
        <Typography fontSize="26px" fontWeight="bold" textAlign="center">
          Lorem ipsum dolor{" "}
        </Typography>
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductData
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
      </Box>
      <Box
        sx={{
          padding: "2rem 5rem",
          display: "flex",
          flexDirection: "column",
          gap: "6rem",
        }}
      >
        <Typography fontSize="26px" fontWeight="bold" textAlign="center">
          Lorem ipsum dolor{" "}
        </Typography>
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductData
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
      </Box>
      <Box
        sx={{
          padding: "2rem 5rem",
          display: "flex",
          flexDirection: "column",
          gap: "6rem",
        }}
      >
        <Typography fontSize="26px" fontWeight="bold" textAlign="center">
          Lorem ipsum dolor{" "}
        </Typography>
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductData
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
        <ProductComponent
          title={productTitle}
          description={productDescription}
          image={productImage}
          titleSty={classes.titleFont}
        />
      </Box>
    </Box>
  );
};

export default OurProducts;
