import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const Button = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    color: "white !important",
    fontSize: "16px !important",
    boxShadow: "none !important",
  },

  contained: {
    background: "#0790D4",
    fontWeight: "bold !important",
    letterSpacing: 1.6,
    textTransform: "capitalize !important",
    borderRadius: "50px !important",
  },
  outlined: {
    position: "relative",
    fontWeight: 600 + " !important",
    color: "white !important",
    background: "#1C2452 !important",
    border: "none !important",
  },
}))(MuiButton);

export default Button;
