import { Box, Typography } from "@mui/material";
import Button from "../button";
import { makeStyles } from "@mui/styles";

const DevelopmentInfo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.descriptionContainer}>
        <Typography className={classes.heading} pb={3}>
          Web development encompasses a wide range of activities involved in
          creating and maintaining websites and web applications. Here are the
          key aspects and details about web development:
        </Typography>
        <Typography className={classes.description}>
          Frontend Development Frontend development focuses on the parts of a
          website or web application that users interact with directly.
          Languages and Technologies: HTML (HyperText Markup Language): Defines
          the structure and content of web pages. CSS (Cascading Style Sheets):
          Controls the presentation and layout of HTML elements. JavaScript:
          Adds interactivity and dynamic behavior to web pages. Frontend
          Frameworks: Such as React.js, Angular, Vue.js, which provide pre-built
          components and tools to streamline development. Responsiveness:
          Ensuring websites adapt and display correctly on different devices and
          screen sizes (desktops, tablets, smartphones). Accessibility:
          Designing websites to be usable by people with disabilities, following
          accessibility standards like WCAG (Web Content Accessibility
          Guidelines).
        </Typography>
        <Typography className={classes.description}>
          Backend Development Backend development focuses on server-side
          operations and database management. Languages and Frameworks: Node.js
          (JavaScript runtime): Popular for building scalable network
          applications. Python (Django, Flask): Known for its simplicity and
          versatility in web development. Ruby (Ruby on Rails): Emphasizes
          convention over configuration and rapid development. PHP: Widely used
          for server-side scripting. Database Management: SQL (Structured Query
          Language): Standard language for managing relational databases (e.g.,
          MySQL, PostgreSQL). NoSQL Databases: Such as MongoDB, used for
          handling unstructured or semi-structured data. Server Management:
          Deploying applications to web servers (e.g., Apache, Nginx) and
          managing server configurations.
        </Typography>
      </Box>
      <Box className={classes.cardContainer}>
        <Typography className={classes.heading}>Get in touch</Typography>
        <Typography className={classes.description}>
          To get in touch with one of our professionals click below
        </Typography>
        <Button variant="outlined">Click</Button>
      </Box>
    </Box>
  );
};

export default DevelopmentInfo;
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#0790D4",
    width: "300px",
    height: "327px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "15px",
    textAlign: "center",
    gap: "30px",
  },
  heading: {
    color: "white",
    fontSize: "28px !important",
    fontWeight: "700 !important",
  },
  description: {
    color: "white",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
  },
  mainHeading: {
    fontSize: "",
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    padding: "20px 65px",
    justifyItems: "end",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
}));
