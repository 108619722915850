import { Box, Typography } from "@mui/material";

const Banner = () => {
  return (
    <Box sx={{ padding: "50px 70px" }}>
      <Typography
        sx={{ color: "#0790D4", fontWeight: 800, fontSize: "40px", padding: 0 }}
      >
        Make
      </Typography>
      <Typography sx={{ color: "#0790D4", fontWeight: 800, fontSize: "40px" }}>
        Something
      </Typography>
      <Typography sx={{ color: "#0790D4", fontWeight: 800, fontSize: "40px" }}>
        Amazing.
      </Typography>
    </Box>
  );
};

export default Banner;
