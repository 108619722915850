import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const BasicMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Typography
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseEnter={handleMouseEnter}
        sx={{
          color: "white",
          fontWeight: "bold !important",
          fontSize: "18px !important",
          cursor: "pointer",
          textDecoration: "none",
          "&:hover": {
            color: "#0790D4",
            borderBottom: "4px solid #0790D4",
          },
        }}>
        Services
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMouseLeave}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          onMouseLeave: handleMouseLeave,
        }}
        sx={{
          position: "absolute",
          top: "1%",
          ".MuiPaper-root": {
            borderRadius: "40px !important",
          },
          ".MuiList-root": {
            background: "#747474",
            display: "flex",
            padding: "10px",
          },
        }}>
        <Box>
          <MenuItem
            className={classes.menuItems}
            component={Link}
            to="/webDevelopment">
            Web Development
          </MenuItem>
          <MenuItem
            className={classes.menuItems}
            component={Link}
            to="/gis-page"
          >
            GIS
          </MenuItem>
          <MenuItem className={classes.menuItems}> Digital Marketing</MenuItem>
          <MenuItem
            className={classes.menuItems}
            component={Link}
            to="/telemarketing">
            {" "}
            Telemarketing
          </MenuItem>
          <MenuItem className={classes.menuItems}>Graphic Design</MenuItem>
          <MenuItem className={classes.menuItems}>2D/3D Design</MenuItem>
        </Box>
        <Box>
          <MenuItem className={classes.menuItems}>E-Commerce</MenuItem>
          <MenuItem className={classes.menuItems}>Acounts & Finance</MenuItem>
          <MenuItem className={classes.menuItems}>UX/UI Design</MenuItem>
          <MenuItem className={classes.menuItems} component={Link} to="payment">
            Payment
          </MenuItem>
          <MenuItem className={classes.menuItems}>Enterpreneurship</MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default BasicMenu;

const useStyles = makeStyles((theme) => ({
  menuItems: {
    color: "white !important",
    fontWeight: "bold !important",
    textDecoration: "none !important",
    "&:hover": {
      color: "#0790D4 !important",
      backgroundColor: "transparent !important",
    },
  },
}));
