import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  contactBg: {
    backgroundColor: "#212626",
  },
  contactFront: {
    height: "99vh",
    backgroundColor: "rgba(68, 99, 114, 0.31)",
    display: "grid",
    gridTemplateColumns: "repeat(18, 1fr)",
    gridTemplateRows: "repeat(18, 1fr)",
  },
  textBpo: {
    fontSize: "5.5rem !important",
    fontWeight: "bold !important",
    color: "#0790D4",
    paddingBottom: "1.5rem",
  },
  textButton: {
    gridColumn: "3/14",
    gridRow: "3/18",
  },
  bpoImage: {
    gridColumn: "12/18",
    gridRow: "3/19",
  },
  button: {
    backgroungColor: "#1A6488",
    fontSize: "20px !important",
    padding: "1rem 2rem !important",
  },
  /////////////////////////////////////
  wholeComponent: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    justifyContent: "center",
    // gap: "4rem",
    alignItems: "center",
    padding: "3rem 3rem ",
  },
  typo: {
    fontSize: "26px !important",
    fontWeight: "bold !important",
    color: "white",
  },
  listTypo: {
    color: "white",
    fontWeight: "bold !important",
    fontSize: "22px !important",
    gridColumn: "2/6",
  },
  listButton: {
    fontSize: "22px !important",
    textTransform: "lowercase !important",
    fontWeight: "bold",
    margin: "0px !important",
    padding: "0px !important",
  },
  imgBox: {
    width: "30vw",
    gridColumn: "8/13",
  },
}));
