import ReduxProvider from "./store/provider";
import { RouterProvider } from "react-router-dom";
import theme from "./theme";
import router from "./routes";
import { ThemeProvider } from "@mui/styles";
import RocketFired from "./components/scrollUp";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider>
        <RocketFired />
        <RouterProvider router={router} />
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
