import { makeStyles } from "@mui/styles";
import aboutBackground from "../../assets/images/aboutBG.png";
export const useStyles = makeStyles((theme) => ({
  aboutUs: {
    backgroundColor: "#212626",
    padding: "2rem 5rem",
  },
  aboutBG: {
    height: "100vh",
    backgroundImage: `url(${aboutBackground})`,
    backgroundSize: "cover",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // paddingLeft: "3rem",
    // paddingBottom: "10rem",
    // gap: "10px",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "repeat(12, 1fr)",
  },
  title: {
    fontSize: "24px !important",
    color: "white",
    fontWeight: "bold !important",
    borderBottom: "4px solid #0790D4",
    width: "10vw",
    padding: "8px",
    marginBottom: "2rem !important",
  },
  development: {
    fontSize: "3rem !important",
    color: "white",
    fontWeight: "bold !important",
  },
  aboutImg: {
    width: "90%",
  },
  typo: {
    fontSize: "1.2rem",
    color: "white",
    paddingTop: "8px",
  },
  positionTitle: {
    gridColumn: "2/9",
    gridRow: "5/13",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
  },
  secondTitle: {
    color: "white !important",
    padding: "3rem 0rem",
    width: "70%",
    display: "flex",
    flexDirection: "column",
  },
}));
