export const typography = () => {
  return {
    fontFamily: ['"Ubuntu"', "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: "normal",
    h1: {},
    h2: {},
    h3: {},
    body1: {},
    button: {},
  };
};
