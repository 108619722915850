import crouselImage from "../../assets/images/coruselImage.png";
import crouselSecondImage from "../../assets/images/bgProduct.png";

export const imagesData = [
  {
    image: crouselSecondImage,
  },
  {
    image: crouselImage,
  },
  {
    image: crouselSecondImage,
  },
];
