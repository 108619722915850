import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useRef } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
const useStyles = makeStyles((theme) => ({
  rocket: {
    position: "fixed",
    right: "2rem",
    borderRadius: "50%",
    border: "2px solid #fff",
    boxShadow: "1px 1px 1px #fff",
    backgroundColor: "transparent",
    bottom: "4rem",
    fontSize: "30px !important",
    color: "#fff",
    cursor: "pointer",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      right: "0.3rem",
    },
  },
}));

const RocketFired = () => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    return () => {
      window.onscroll = null; // Cleanup on unmount
    };
  }, []);

  const scrollToTop = (duration) => {
    const start = window.pageYOffset;
    const startTime =
      "now" in window.performance ? performance.now() : new Date().getTime();

    const animateScroll = () => {
      const now =
        "now" in window.performance ? performance.now() : new Date().getTime();
      const timeElapsed = now - startTime;
      const ease = Math.sqrt(timeElapsed / duration);

      window.scroll(0, start - start * ease);

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        window.scroll(0, 0);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const iconRef = useRef(null);

  useEffect(() => {
    if (iconRef.current) {
      iconRef.current.style.display = offset >= 1000 ? "block" : "none";
    }
  }, [offset]);

  return (
    <div>
      <a href="#top" onClick={() => scrollToTop(1000)}>
        <ExpandLessIcon
          ref={iconRef}
          className={classes.rocket}
          variant="contained"
        />
      </a>
    </div>
  );
};

export default RocketFired;
