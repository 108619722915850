import React from "react";
import { useStyles } from "./style";
import { Box, Typography } from "@mui/material";
import ProductData from "../../components/productData";
import lineImg from "../../assets/images/Rectangle 1557.png";
import ProductComponent from "../../components/productComponent";
import productImage from "../../assets/images/proPic.png";

const TelemarketingPage = () => {
  const classes = useStyles();
  const tileOne = "BPO";
  const description =
    "Dispatching a website, also known as deploying a website, involves taking your completed web application from a development environment and making it available on the internet. Here’s a comprehensive guide to the deployment process. ";
  const titleTwo = "Dispatch";
  return (
    <Box sx={{ backgroundColor: "#212626" }}>
      <Box className={classes.productBackground}>
        <Box className={classes.title}>
          <h1 className={classes.infoTitle}>Telemarketing </h1>
          <Typography className={classes.paragraph}>
            Telemarketing is a method of direct marketing where sales
            representatives use the telephone to solicit prospective customers
            to buy products or services. It is a widely used tactic due to its
            direct approach and personal interaction.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "2rem 5rem" }}>
        <Typography
          sx={{
            fontSize: "2rem",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Telemarketing
        </Typography>
        <img
          src={lineImg}
          alt="line"
          style={{ padding: "0rem 0rem 2rem 0rem", width: "20%" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <ProductData
            title={tileOne}
            description={description}
            image={productImage}
            titleSty={classes.titleStyle}
          />
          <ProductComponent
            title={titleTwo}
            description={description}
            image={productImage}
            titleSty={classes.titleStyle}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TelemarketingPage;
