import { Box, Button, Typography } from "@mui/material";
import React from "react";
import blogImage from "../../assets/images/Rectangle 1548.png";
import { useStyle } from "./style";
import { NavLink } from "react-router-dom";

const BlogTwoContainer = ({ blogsID }) => {
  const classes = useStyle();

  const blogContentTwo = {
    id: 2,
    title: "The Role of Graphic Design in Digital Marketing",
    image: blogImage,
    content:
      ` Graphic design is a powerful tool in the digital marketing arsenal. It
          helps create visually appealing content that can captivate audiences,
          convey messages effectively, and enhance brand identity. In this blog
          post, we’ll explore the role of graphic design in digital marketing
          and how it can be leveraged to boost engagement and conversions.
          Visual Identity A strong visual identity is essential for any brand.
          It includes elements like logos, color schemes, typography, and
          imagery that represent your brand’s personality and values. Logo
          Design: A memorable logo is the cornerstone of your brand identity.
          Color Psychology: Use colors strategically to evoke emotions and
          convey messages. Typography: Choose fonts that complement your brand’s
          voice and are easy to read. Content Creation Graphic design plays a
          crucial role in creating compelling content for various digital
          platforms. Social Media Graphics: Eye-catching visuals for social
          media posts can increase engagement and shares. Infographics:
          Infographics present information in a visually appealing and
          easy-to-understand format. Email Marketing: Professionally designed
          email templates can improve open and click-through rates.`,
    title2: "The Role of Graphic Design in Digital Marketing 2",
    content2: ` Graphic design is a powerful tool in the digital marketing arsenal. It
    helps create visually appealing content that can captivate audiences,
    convey messages effectively, and enhance brand identity. In this blog
    post, s. Social Media Graphics: Eye-catching visuals for social
    media posts can increase engagement and shares. Infographics:
    Infographics present information in a visually appealing and
    easy-to-understand format. Email Marketing: Professionally designed
    email templates can improve open and click-through rates. 2`,
    title3: "Content Creation",
    content3: `Graphic design plays a crucial role in creating compelling content...`
  }
  return (
    <Box className={classes.containerBg}>
      <Box>
        <Typography sx={{ fontWeight: 800 }}>
          {blogContentTwo.title}
        </Typography>
        <Typography pt={2}>
          {blogContentTwo.content}
        </Typography>
        <Box className={classes.buttonBox} pt={5}>
          <NavLink to={`/blog/${blogContentTwo.id}`} state={{ blogContentTwo }}>
            <Button variant="contained" >Details</Button>
          </NavLink>
        </Box>
      </Box>
      <Box className={classes.imageBox}>
        <img src={blogImage} alt="relax" width="95%" height="98%" />
      </Box>
    </Box>
  );
};

export default BlogTwoContainer;
