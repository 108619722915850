import Carousel from "react-elastic-carousel";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CarouselArrow } from "../../utils";
import { imagesData } from "./data";
import { useStyles } from "./style";
import HomeServices from "../../components/services";
import Button from "../../components/button";
import CoursesDetails from "../../components/courseDetails";
const Courses = () => {
  const isMobileView = useMediaQuery("(max-width:600px)");
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isMobileCarousel = useMediaQuery("(max-width:600px)");
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 700, itemsToShow: 2, itemsToScroll: 2, itemPadding: [0, 10] },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3, itemPadding: [0, 20] },
    { width: 1200, itemsToShow: 3, itemPadding: [0, 20] },
  ];
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          // breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
        >
          {imagesData.map(({ image }) => {
            return <img src={image} alt="" />;
          })}
        </Carousel>
      </Box>
      <Box className={classes.headingContainer} pt={3}>
        <Typography className={classes.heading}>Courses</Typography>
        <Typography className={classes.description}>
          Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis
          tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur.
          Natoque malesuada nunc mattis tincidunt gravida aliquam.
        </Typography>
      </Box>
      <Box pt={3}>
        <HomeServices />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button className={classes.buttons}>Online Live Courses</Button>
        <Button className={classes.buttons}>Recorded Courses</Button>
        <Button className={classes.buttons}>On-Site Courses</Button>
      </Box>
      <Box>
        <CoursesDetails />
      </Box>
    </Box>
  );
};

export default Courses;
