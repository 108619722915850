import { Box, InputBase, Typography, Divider } from "@mui/material";

const PaymentDetails = () => {
  return (
    <Box display="flex">
      <Box p={1} flex="1">
        <Box>
          <Typography sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}>
            As a company culture we are <br /> always open
          </Typography>
          <Typography sx={{ color: "#fff", fontSize: "16px" }} pt={4}>
            We will always be here to accommodate you
          </Typography>
        </Box>
        <Box pt={5}>
          <InputBase
            sx={{
              backgroundColor: "#145b7d",
              width: "60%",
              padding: 1.5,
            }}
            placeholder="Company Name"
          />
        </Box>
        <Box pt={3}>
          <InputBase
            sx={{
              backgroundColor: "#fff",
              width: "80%",
              padding: 0.5,
              borderRadius: "5px",
              marginTop: "1rem",
            }}
            placeholder="Short details...."
            multiline={true}
            rows={7}
          />
        </Box>
        <Box pt={3}>
          <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: 500 }}>
            As a company culture we are always open to connect with you.
          </Typography>
          <Typography sx={{ color: "#fff", fontSize: "16px" }} pt={3}>
            As a company culture we are always open to connect with institutions
            and people who are curious to put their effort into the development
            of humans and societies using information technology. For our
            in-house team, we do organize different events and seminars for
            holistic development.
            <Box sx={{ height: "30vh", display: "flex", alignItems: "end" }}>
              <Box>
                {" "}
                <Typography>
                  Please note : Payments are processed & handled by Tagmango
                  using multiple payment gateways.
                </Typography>
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>

      <Divider
        orientation="vertical"
        sx={{ borderColor: "#fff", height: "100vh", ml: 2 }}
      />
    </Box>
  );
};

export default PaymentDetails;
