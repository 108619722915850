import { Box, IconButton, Typography } from "@mui/material";
import LifeAtFranski from "../../assets/images/life.png";
// import messengerIcon from "../../assets/images/messengerIcon.png";
import MessageIcon from "@mui/icons-material/Message";
import { Link } from "react-router-dom";
const LifeFranski = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: 800, fontSize: "40px" }}>
          Life at Franski
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "14px",
            fontWeight: 800,
            textTransform: "uppercase",
          }}
        >
          FOLLOW UP ON RECENT HAPPNINGS AT FRANSKI ENTERPRISE
        </Typography>
        <Typography sx={{ color: "white", fontSize: "14px" }}>
          As a company culture we are always open to connect with institutions
          and people who are curious to put their effort into the development{" "}
          <br /> of humans and societies using information technology. For our
          in house team, we do organize different events and seminars for
          holistic <br /> development.
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative", // Make this container relative to position the icon absolutely
          width: "100%",
          height: "100vh", // Full height, adjust as needed
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center contents horizontally and vertically
        }}
      >
        <Box>
          <img src={LifeAtFranski} alt="" />
        </Box>
        <Box
          sx={{
            position: "absolute", // Positioning it absolutely within the relative parent
            bottom: 80, // Distance from the bottom of the parent container
            right: 70, // Distance from the right of the parent container
            bgcolor: "rgba(7, 144, 212, 1)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1, // Padding around the icon
          }}
        >
          <IconButton component={Link} to="service">
            <MessageIcon sx={{ color: "white", fontSize: "50px" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LifeFranski;
