import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentModalDetails from "../paymentModalDetails";
import logo from "../../assets/images/logo.png";

const PaymentModal = ({ open, handleClose }) => {
  const [paymentMethod, setPaymentMethod] = useState("upi");
  const [openCard, setOpen] = useState(false);

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseCard = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            borderRadius: 2,
          }}>
          <Box
            sx={{
              bgcolor: "#212626",
              borderRadius: "8px 8px 0px 0px !important",
            }}>
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}>
              <Box
                component="img"
                src={logo}
                alt="Company Logo"
                sx={{ height: 40 }}
              />
              <Button
                onClick={handleClose}
                sx={{
                  minWidth: "auto",
                  fontSize: "1.8rem",
                  padding: "0 12px",
                  color: "white",
                }}>
                ✕
              </Button>
            </Box>
          </Box>
          <Box sx={{ padding: "1rem" }}>
            <Typography variant="h6" component="h2">
              Preferred Payment Methods
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <PaymentIcon sx={{ color: "#1976d2" }} />
              <FormControl component="fieldset">
                <RadioGroup
                  value={paymentMethod}
                  onChange={handlePaymentChange}>
                  <FormControlLabel
                    value="upi"
                    control={<Radio />}
                    label="UPI QR"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" component="h2">
              Cards, UPI & More
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
     
              <Button
                onClick={() => {
                  handleClose();
                  handleClickOpen();
                }}
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CreditCardIcon sx={{ mr: 2 }} />
                  Card
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-visa-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-mastercard-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-amex-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                </Box>
              </Button>

              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <QrCodeScannerIcon sx={{ mr: 2 }} />
                  UPI / QR
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-visa-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-mastercard-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                  <Box
                    component="span"
                    sx={{
                      background: "url(/path-to-amex-icon) no-repeat",
                      width: "24px",
                      height: "24px",
                    }}></Box>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccountBalanceIcon sx={{ mr: 2 }} />
                  Netbanking
                </Box>
                <Typography>All Pakistan banks</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AttachMoneyIcon sx={{ mr: 2 }} />
                  EMI
                </Box>
                <Typography>EMI via cards, OneCard & more</Typography>
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              alignItems: "center",
              padding: "1rem 1rem",
              boxShadow: "2px 0px 25px",
              marginTop: "2rem",
            }}>
            {" "}
            <Typography variant="h4" component="p" sx={{ gridColumn: "1/4" }}>
              $ 55
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "5px",
                gridColumn: "5/7",
                padding: "1rem 1rem",
              }}>
              Pay Now
            </Button>
          </Box>
        </Box>
      </Modal>

      <PaymentModalDetails open={openCard} handleCloseCard={handleCloseCard} />
    </Box>
  );
};

export default PaymentModal;
