import { Box, Typography } from "@mui/material";
import Button from "../button";
import PaymentModal from "../paymentModal";
import { useState } from "react";
const PaymentEntry = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Box sx={{ paddingLeft: "1rem" }}>
      <Box>
        <Typography sx={{ fontSize: "24px", color: "#fff", fontWeight: 700 }}>
          Payment Process
        </Typography>
        <Typography pt={3} sx={{ fontSize: "16px", color: "#fff" }}>
          As a company culture we are always open
        </Typography>
      </Box>
      <Box pt={7}>
        <Box
             sx={{
              padding: 1,
              display: "flex",
              border: "4px solid transparent ", 
              flexDirection: "column",
              marginBottom: "3.5rem",
              borderRadius:"5px",
              marginTop: "1rem",
              borderImage: "linear-gradient(to bottom, #0790D4, rgba(7, 144, 212, .2)) 1", 
            }}>
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "white",
              }}>
              <Box> Your Total:</Box>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: 1.5,
            display: "flex",
            border: "4px solid transparent",
            borderRadius: "5px",
            flexDirection: "column",
            gap: 2,
            borderImage: "linear-gradient(to bottom, #0790D4, rgba(7, 144, 212, .2)) 1",
          }}>
          <Box sx={{ borderBottom: "1px dotted #0790D4" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "white",
              }}>
              <Box sx={{ paddingBottom: "5px" }}> Your Total:</Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "white",
                  }}>
                  Price:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "white",
                    paddingBottom: "1rem",
                  }}>
                  50$
                </Typography>
              </Box>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <Typography
              sx={{
                fontSize: "16px",
                color: "white",
              }}>
              Price:
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "white" }}>
              50$
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={5}>
        <Button
          onClick={handleOpen}
          sx={{
            borderRadius: "5px",
            backgroundColor: "#145b7d",
            width: "100%",
            padding: "10px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#297ba0",
            },
          }}>
          Proceed to pay $4799
        </Button>
        <PaymentModal open={modalOpen} handleClose={handleClose} />
      </Box>
    </Box>
  );
};
export default PaymentEntry;
