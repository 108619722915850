import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  gisBackground: {
    padding: "4rem 0rem",
    backgroundColor: "#212626",
    height: "100vh",
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
  },
  gisInput: {
    width: "50vw",
    padding: "15px 5px",
    backgroundColor: "transparent",
    outline: "none",
    border: "1px solid white",
    borderRadius: "5px",
    color: "white",
    fontSize: "16px",
  },
  gisTextArea: {
    width: "50vw",
    height: "140px",
    padding: "7px",
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: "5px",
    fontSize: "16px",
    color: "white",
  },
  bottomBox: {
    width: "50vw",
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gap: "1rem",
  },
  gisButton: {
    padding: "0.6rem 2.5rem !important",
  },
}));
