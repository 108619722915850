export const palette = {
  primary: {
    main: "#2E3044",
  },
  secondary: {
    main: "#1B1934",
  },
  background: {
    paper: "#FFFFFF26",
    default: "",
  },
  text: {
    primary: "#ffffff",
  },
  divider: "#63B5E4",
};
