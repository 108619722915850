import { Box, Typography } from "@mui/material";
import Image from "../../assets/images/productImages.png";
const ProductsHome = () => {
  return (
    <Box pt={2}>
      <Box textAlign={"center"}>
        <Typography
          sx={{
            color: "white",

            fontSize: "40px",
            fontWeight: 800,
          }}
        >
          Our Products
        </Typography>
        <Typography sx={{ color: "white", fontSize: "14px", fontWeight: 800 }}>
          STARTUPS BY FRANSKI ENTERPRISE
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        py={4}
      >
        <img src={Image} alt="" />
      </Box>
    </Box>
  );
};

export default ProductsHome;
