import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  wholeComponent: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 3rem ",
  },
  typo: {
    fontSize: "26px !important",
    fontWeight: "bold !important",
    color: "white",
  },
  listTypo: {
    color: "white",
    fontWeight: "bold !important",
    fontSize: "22px !important",
    gridColumn: "8/12",
  },
  listButton: {
    fontSize: "22px !important",
    textTransform: "lowercase !important",
    fontWeight: "bold",
    margin: "0px !important",
    padding: "0px !important",
  },
  imgBox: {
    width: "30vw",
    gridColumn: "2/6",
  },
}));
