import { makeStyles } from "@mui/styles";
import backgroundImage from "../../assets/images/bgProduct.png";
export const useStyles = makeStyles((theme) => ({
  productPage: {
    backgroundColor: "#212626",
    color: "white",
  },

  productBackground: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",

    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    alignItems: "center",
    color: "white",
  },
  infoTitle: {
    gridColumn: "2/7",
  },
  paragraph: {
    gridColumn: "2/7",
  },
  productIntro: {
    padding: "1rem 0rem 1rem 7rem",
    width: "50vw",
  },
  titleFont: {
    fontSize: "1.4rem !important",
    fontWeight: "bold !important",
  },
}));
