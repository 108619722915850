import { makeStyles } from "@mui/styles";
import backgroundImage from "../../assets/images/bgProduct.png";
export const useStyles = makeStyles((theme) => ({
  productPage: {
    backgroundColor: "#212626",
    padding: "2rem 0rem",
    color: "white",
  },

  productBackground: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",

    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    alignItems: "center",
    color: "white",
  },
  infoTitle: {
    gridColumn: "2/7",
  },
  paragraph: {
    gridColumn: "2/7",
  },
  titleStyle: {
    fontSize: "3rem !important",
    fontWeight: "700 !important",
    color: "#0790D4",
  },
}));
