import { Box, Typography } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import { makeStyles } from "@mui/styles";
import Button from "../button";
import { Link } from "react-router-dom";
import BasicMenu from "../dropDown";

const Header = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.container}>
        <Box component={Link} to="/">
          <img style={{ cursor: "pointer" }} src={Logo} alt="" />
        </Box>
        <Box className={classes.mainContainer}>
          <Typography className={classes.typos} component={Link} to="/">
            Home
          </Typography>
          
          <BasicMenu />
          <Typography className={classes.typos} component={Link} to="/courses">
            Courses
          </Typography>
          <Typography className={classes.typos} component={Link} to="/products">
            Products
          </Typography>
          <Typography className={classes.typos} component={Link} to="/blogs">
            Blogs
          </Typography>
          <Button variant="contained" component={Link} to="/contact">
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#212626",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 70px",
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between",
    gap: 30,
  },
  typos: {
    color: "white",
    fontWeight: "bold !important",
    fontSize: "18px !important",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#0790D4",
      borderBottom: "4px solid #0790D4",
    },
  },
}));
