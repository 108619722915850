import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";

const services = [
  ["Web Development", "Graphic Design", "UX/UI Design"],
  ["GIS", "E-Commerce", "Films & Production"],
  ["Digital Marketing", "Stock Trading", "Entrepreneurship"],
  ["Telemarketing", "Accounts & Finance", "2D/3D Design"],
];

const Services = () => {
  return (
    <Container
      style={{
        padding: "2rem 0",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {services.map((row, rowIndex) => (
          <Grid
            container
            spacing={0}
            justifyContent="center"
            key={rowIndex}
            sx={{ borderTop: rowIndex === 0 ? "none" : "1px solid #444" }}
          >
            {row.map((service, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                key={index}
                sx={{
                  borderRight:
                    index < row.length - 1 ? "2px solid #fff" : "none",
                  borderBottom:
                    rowIndex < services.length - 1 ? "1px solid #fff" : "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 100,
                    color: "white",
                    textAlign: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box sx={{ padding: "12px", background: "white" }}></Box>
                  <Typography variant="body1">{service}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
    </Container>
  );
};

export default Services;
