import { Box, Divider, Typography } from "@mui/material";
import Banner from "../../components/banner";
import { useStyle } from "./style";
import HomeServices, { OurServices } from "../../components/services";
import ProductsHome from "../../components/products";
import LifeFranski from "../../components/lifeFranski";
// import CircleComponent from "../../components/satisfiedClients";
import SatisfiedClients from "../../components/satisfiedClients";
const Home = () => {
  const classes = useStyle();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <Box>
          <Banner />
        </Box>
        <Box></Box>
      </Box>
      <Box pt={10}>
        <Divider sx={{ borderColor: "#0790D4", borderBottomWidth: "thick" }} />
      </Box>
      <Box>
        <Box sx={{ textAlign: "center" }} pt={5}>
          <Typography
            sx={{ color: "#0790D4", fontWeight: 800, fontSize: "40px" }}
          >
            Services
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "18px",
              fontWeight: 800,
              textTransform: "uppercase",
            }}
          >
            Work with us, we've got you covered
          </Typography>
        </Box>
        <HomeServices />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "305px",
          // width: "100%",
          backgroundColor: "#D9D9D966",
          padding: "2rem",
          borderRadius: "20px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <SatisfiedClients />
        <SatisfiedClients />
        <SatisfiedClients />
        <SatisfiedClients />
      </Box>
      <Box>
        <ProductsHome />
      </Box>
      <Box pb={3}>
        <OurServices />
      </Box>
      <Box>
        <LifeFranski />
      </Box>
    </Box>
  );
};
export default Home;
