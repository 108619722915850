import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/images/logo.png";
import FacebookIcon from "../../assets/images/facebook.png";
import InstagramIcon from "../../assets/images/insta.png";
import YoutubeIcon from "../../assets/images/youtube.png";
import { Link } from "react-router-dom";
import Twitter from "../../assets/images/twitter.png";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box pt={2} pl={20} display={"flex"} justifyContent={"center"}>
        <img src={Logo} alt="logo" />
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.secondContainer}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <Typography className={classes.heading}>Home</Typography>
            <Typography className={classes.subHeading}>FAQ</Typography>
            <Typography className={classes.subHeading}>Contact Us</Typography>
            <Typography className={classes.subHeading}>Gallery</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <Link to="about" style={{ textDecoration: "none" }}>
              {" "}
              <Typography className={classes.heading}>About Us</Typography>
            </Link>
            <Typography className={classes.subHeading}>Contact Us</Typography>
            <Typography className={classes.subHeading}>Our Story</Typography>
            {/* <Typography className={classes.subHeading}>Contact Us</Typography> */}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            {" "}
            <Typography className={classes.heading}>Blog</Typography>
            <Typography className={classes.subHeading}></Typography>
            <Typography className={classes.subHeading}></Typography>
            <Typography className={classes.subHeading}></Typography>
          </Box>
        </Box>
        <Box className={classes.socialIcons}>
          <img src={FacebookIcon} className={classes.icon} alt="facebook" />
          <img src={InstagramIcon} className={classes.icon} alt="instagram" />
          <img src={YoutubeIcon} className={classes.icon} alt="youtube" />
          <img src={Twitter} className={classes.icon} alt="twitter" />
        </Box>
      </Box>
      <Box className={classes.lastContainer}>
        <Box>
          {" "}
          <Typography
            sx={{ color: "#848484", fontSize: "14px", fontWeight: 500 }}
          >
            2022 @ Franski Enterprise Technologies Private Limited. All rights
            reserved.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyItems: "space-around", gap: 5 }}>
          <Typography className={classes.typos}>News and Events</Typography>
          <Typography className={classes.typos}>Privacy Policy</Typography>
          <Typography className={classes.typos}>Cookie Policy</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#1B1B1B",
  },
  secondContainer: {
    display: "flex",
    width: "70vw",
    justifyContent: "space-between",
  },
  socialIcons: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 20,
  },
  icon: {
    width: "40px",
    height: "40px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px 165px 70px 165px",
  },
  lastContainer: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-around",
    padding: "16px 20px",
  },
  typos: {
    fontSize: "19px",
    fontWeight: 700 + "!important",
    cursor: "pointer",
  },
  heading: {
    color: "white",
    fontWeight: 600 + "!important",
    fontSize: "19px !important",
  },
  subHeading: {
    fontSize: "15px !important",
    fontWeight: 400 + "!important",
    color: "white",
    cursor: "pointer",
  },
}));
