import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../button";

const CoursesDetails = () => {
  const classes = useStyles();
  return (
    <Box py={2}>
      <Box className={classes.headingContainer} pt={3}>
        <Typography className={classes.heading}>Online Live Courses</Typography>
        <Typography className={classes.description}>
          Online live courses represent a significant evolution in the realm of
          education, leveraging technology to provide real-time, interactive
          learning experiences to students around the globe. These courses offer
          a blend of the flexibility of online education with the immediacy and
          engagement of traditional classroom settings.
        </Typography>
        <Box className={classes.btnContainer}>
          {" "}
          <Button className={classes.buttons}>Details</Button>
        </Box>
      </Box>
      <Box className={classes.headingContainer} pt={3}>
        <Typography className={classes.heading}>Recorded Courses</Typography>
        <Typography className={classes.description}>
          Recorded courses are pre-recorded instructional videos and materials
          that learners can access at their convenience. These courses have
          revolutionized education by making high-quality learning resources
          available to anyone with an internet connection, at any time.
        </Typography>
        <Box className={classes.btnContainer}>
          {" "}
          <Button className={classes.buttons}>Details</Button>
        </Box>
      </Box>{" "}
      <Box className={classes.headingContainer} pt={3}>
        <Typography className={classes.heading}>On Site Courses</Typography>
        <Typography className={classes.description}>
          On-site courses, also known as in-person or classroom courses, are the
          traditional mode of education where learners and instructors
          physically gather in the same location. This method has been the
          cornerstone of education for centuries, providing a structured and
          interactive environment for learning.
        </Typography>
        <Box className={classes.btnContainer}>
          {" "}
          <Button className={classes.buttons}>Details</Button>
        </Box>
      </Box>{" "}
      <Box className={classes.headingContainer} pt={3}>
        <Typography className={classes.heading}>Graphic Design</Typography>
        <Typography className={classes.description}>
          Graphic design is the art and practice of planning and projecting
          ideas and experiences with visual and textual content. It plays a
          pivotal role in shaping how we perceive and interact with information,
          brands, and environments.
        </Typography>
        <Box className={classes.btnContainer}>
          {" "}
          <Button className={classes.buttons}>Details</Button>
        </Box>
      </Box>{" "}
    </Box>
  );
};

export default CoursesDetails;
const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#fff",
    fontWeight: 700 + "!important",
    fontSize: "40px !important",
    borderBottom: "5px solid #0790D4",
    // width: "25%",
  },
  description: {
    fontWeight: 400 + "!important",
    fontSize: "18px !important",
    color: "#fff",
    fontFamily: "Poppins !important",
    padding: "20px 0px !important",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 80px",
  },
  buttons: {
    background: "rgba(7, 144, 212, 0.5) !important",
    textTransform: "capitalize !important",
    padding: "10px 40px !important",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
  },
}));
