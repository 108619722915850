import { Box, Button, Typography } from "@mui/material";
import React from "react";

import blogImage1 from "../../assets/images/blog1.jpg";
import { useStyle } from "./style";
import { NavLink } from "react-router-dom";
const BlogDataComponent = ({ blogsID }) => {
  const classes = useStyle();

  const blogContent = {
    id: 1,
    title: "The Essentials of Web Development",
    content: `
    Web development is a critical component of creating a powerful online presence. 
    Whether you’re building a personal blog, an e-commerce platform, or a corporate site, 
    understanding the fundamentals of web development can significantly impact your site's success. 
    This blog post will delve into the core aspects of web development, including front-end and back-end development, 
    responsive design, and the importance of SEO.
    
    Front-End Development Front-end development focuses on the part of the website that users interact with. 
    It involves using technologies like HTML, CSS, and JavaScript to create a visually appealing and user-friendly interface. 
    HTML/CSS: The building blocks of any website, HTML structures the content while CSS styles it. JavaScript adds interactivity to the site, 
    making it dynamic and engaging. Frameworks and Libraries like React, Angular, and Vue.js can streamline the development process and enhance functionality.
  `,
    image: blogImage1,
    title2: "The Essentials of Full-Stack Development",
    content2: `Full-stack development is a comprehensive approach to building web applications that involves both front-end and back-end technologies. On the front end, developers use HTML, CSS, and JavaScript to create user interfaces that are engaging, intuitive, and responsive. This includes designing layouts, implementing interactivity, and ensuring cross-browser compatibility to provide a seamless user experience.

    On the back end, full-stack developers handle server-side logic, database management, and application integration. They work with server-side languages such as Python, Ruby, Java, or Node.js, and interact with databases like MySQL, MongoDB, or PostgreSQL to store and retrieve data. This part of the stack ensures that data is processed efficiently, security is maintained, and the application scales effectively.
    
    Full-stack development requires a broad skill set, including understanding APIs, version control systems like Git, and deployment practices. By managing both the client-side and server-side aspects of an application, full-stack developers ensure that all components work together smoothly, delivering a unified and functional product that meets users' needs.`
    ,
    title3: "Mastering Web Application Development",
    content3: `Web application development integrates front-end design with back-end logic to create responsive, user-friendly interfaces powered by robust server-side functionality. By mastering both aspects, developers ensure seamless, scalable, and secure applications that deliver a cohesive user experience.`
  }
  return (
    <Box className={classes.containerBg}>
      <Box className={classes.imageBox}>
        <img src={blogContent.image} alt="img" width="95%" height="98%" />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 800 }}>
          {blogContent.title}
        </Typography>

        <Typography pt={2}>
          {blogContent.content}
        </Typography>
        <Box className={classes.buttonBox} pt={5}>
          <NavLink to={`/blog/${blogContent.id}`} state={{ blogContent }}>
            <Button variant="contained">Details</Button>
          </NavLink>
        </Box>
      </Box>
    </Box >
  );
};

export default BlogDataComponent;
