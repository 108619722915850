import React, { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputBase,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const ChatPage = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: "As a company culture", from: "me" },
    { id: 2, text: "As a company culture culture culture", from: "them" },
    { id: 3, text: "As a company culture culture culture", from: "me" },
    { id: 4, text: "As a company culture culture culture", from: "them" },
    { id: 5, text: "As a company culture culture culture", from: "me" },
    { id: 4, text: "As a company culture culture culture", from: "them" },
    { id: 5, text: "As a company culture culture culture", from: "me" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState(null);

  const handleSendMessage = () => {
    if (newMessage.trim() || attachment) {
      setMessages([
        ...messages,
        { id: messages.length + 1, text: newMessage, from: "me", attachment },
      ]);
      setNewMessage("");
      setAttachment(null);
    }
  };

  const handleAttachmentChange = (event) => {
    if (event.target.files.length > 0) {
      setAttachment(event.target.files[0]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#1e1e1e",
        color: "#fff",
      }}
    >
      <List sx={{ flexGrow: 1, overflowY: "auto" }}>
        {messages.map((message) => (
          <ListItem
            key={message.id}
            sx={{
              justifyContent: message.from === "me" ? "flex-end" : "flex-start",
            }}
          >
            {message.from !== "me" && (
              <ListItemAvatar>
                <Avatar>{message.from.charAt(0).toUpperCase()}</Avatar>
              </ListItemAvatar>
            )}
            <Box
              sx={{
                bgcolor: message.from === "me" ? "#1e88e5" : "#424242",
                p: 1.5,
                borderRadius: 2,
                maxWidth: "60%",
              }}
            >
              <ListItemText primary={message.text} />
              {message.attachment && (
                <Box mt={1}>
                  <a
                    href={URL.createObjectURL(message.attachment)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1e88e5" }}
                  >
                    {message.attachment.name}
                  </a>
                </Box>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          borderTop: "1px solid #424242",
        }}
      >
        <IconButton color="primary" component="label">
          <input type="file" hidden onChange={handleAttachmentChange} />
          <AttachFileIcon />
        </IconButton>
        <InputBase
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          sx={{
            flexGrow: 1,
            bgcolor: "#fff",
            color: "#000",
            borderRadius: "50px !important",
            mx: 1,
            padding: 1,
          }}
          InputProps={{
            style: { color: "#fff" },
          }}
        />
        <IconButton color="primary" onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatPage;
