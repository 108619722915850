import React from "react";
import { Box, Typography } from "@mui/material";
import productImage from "../../assets/images/proPic.png";

const ProductComponent = ({ title, description, image, titleSty }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        justifyContent: "center",

        gap: "1rem",
        color: "white",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.4rem" }}>
        <Typography className={titleSty}>{title}</Typography>
        <Typography>{description}</Typography>
      </Box>
      <Box sx={{ height: "75vh", width: "40vw" }}>
        <img src={image} alt="productImg" width="100%" height="100%" />
      </Box>
    </Box>
  );
};

export default ProductComponent;
