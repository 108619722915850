import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    mainContainerbg: {
        backgroundColor: "#212626",
        padding: "0.5rem 4.6rem",
    },
    blogTitle: {
        margin: "0px",
        fontSize: "2rem",
        fontWeight: "bold",
        color: "#ffff",
        width: "16vw",
    },
    firstContainer: {
        borderRadius: "1rem",
        backgroundColor: "rgb(47, 50, 50)",
        width: "100%",
    },
    borderDiv: {
        border: "2px solid #0790D4",
        borderRadius: "5px",
        width: "15vw",
    },
    mainImg: {
        width: "100%",
        height: "95vh",
        borderRadius: "5px",
        marginBottom: "1rem",
    },
    blogTitle2: {
        color: "#ffff",
        width: "60vw",
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: "2rem",
    },
    blogTopPara: {
        width: "65vw",
        paddingLeft: "20px",
        color: "#ffff",
        fontSize: "1.2rem",
        marginBottom: "1rem",
    },
    yearTopPara: {
        color: "#ffff",
        fontSize: "0.8rem",
        marginBottom: "1rem",
    },
    blogImage1Nested: {
        width: "auto",
        height: "auto",
        borderRadius: "5px",
        marginBottom: "1rem",
    },
    secondContainer: {
        width: "45%",
        position: "relative",
    },
    blogBox: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "1rem",
        padding: "1rem",
        borderRadius: "5px",
        gap: "1rem",
        // backgroundColor: "rgb(47, 50, 50)",
    },
    imagesBox: {
        // position: "relative",
        width: "90%",
        height: "70vh",
    },
    blogParaSC: {
        color: "#ffff",
        width: "30vw",
        fontSize: "1rem",
        marginBottom: "1rem",
    },
    secondSubCon: {
        position: "absolute",
        bottom: "5%",
        left: "5%",
    },
}));