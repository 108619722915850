import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgba(33, 38, 38, 1)",
    display: "grid",
    height: "100vh",
    justifyContent: "space-between",
    padding: "70px 80px 0 80px",
    gridTemplateColumns: "1fr 1fr",
  },
}));
