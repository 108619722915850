import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const ServicesCard = ({
  image,
  name,
  backgroundColor,
  description,
  textColor,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} sx={{ background: backgroundColor }}>
      <Box pb={2}>
        <img src={image} alt="" />
      </Box>
      <Box>
        <Typography className={classes.title} sx={{ color: textColor }}>
          {name}
        </Typography>
        <Divider
          sx={{
            width: "40px",
            borderColor: textColor,
            borderBottomWidth: "thick",
          }}
        />
        <Typography className={classes.description} sx={{ color: textColor }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ServicesCard;
const useStyles = makeStyles((theme) => ({
  container: {
    width: "260px",
    height: "330px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5) !important",
  },
  title: {
    fontWeight: "800 !important",
    fontSize: "22px !important",
    width: "50% !important",
  },
  description: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    paddingTop: "5px !important",
  },
}));
