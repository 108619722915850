import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import Layout from "../components/layout";
import Error404 from "../pages/404";
import BlogsComponent from "../pages/blogs";
import WebDevelopment from "../pages/webDevelopment";
import OurProducts from "../pages/ourProducts";
import About from "../pages/aboutUs";
import Courses from "../pages/courses";
import ChatPage from "../pages/chatSystem";
import Payment from "../pages/payment";
import TelemarketingPage from "../pages/telemarketing";
import ContactCenter from "../pages/contactCenter/index";
import NestedBlogComponents from "../pages/blogs/nestedBlogs";
import GisPage from "../pages/Gis";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "blogs", element: <BlogsComponent /> },
      { path: "blog/:blogsID", element: <NestedBlogComponents /> },
      { path: "products", element: <OurProducts /> },
      { path: "webDevelopment", element: <WebDevelopment /> },
      { path: "about", element: <About /> },
      { path: "courses", element: <Courses /> },
      { path: "service", element: <ChatPage /> },
      { path: "payment", element: <Payment /> },
      { path: "telemarketing", element: <TelemarketingPage /> },
      { path: "contact", element: <ContactCenter /> },
      { path: "gis-page", element: <GisPage /> },
    ],
  },

  { path: "*", element: <Error404 /> },
]);

export default router;
