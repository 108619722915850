import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: "#212626",
  },
  heading: {
    color: "#fff",
    fontWeight: 700 + "!important",
    fontSize: "40px !important",
    borderBottom: "5px solid #0790D4",
    // width: "25%",
  },
  description: {
    fontWeight: 400 + "!important",
    fontSize: "18px !important",
    color: "#fff",
    fontFamily: "Poppins !important",
    padding: "20px 0px !important",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 80px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 20,
    padding: "30px 0px",
  },
  buttons: {
    background: "rgba(7, 144, 212, 0.5) !important",
    textTransform: "capitalize !important",
    padding: "10px 40px !important",
  },
}));
