import React from "react";
import { Box, Button, Typography } from "@mui/material";
import handImg from "../../assets/images/contact (2).png";
import { useStyles } from "./style";
const ContactComponentTwo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wholeComponent}>
      <Box className={classes.imgBox}>
        <img src={handImg} alt="laptop" width="100%" />
      </Box>
      <Box className={classes.listTypo}>
        <Typography className={classes.typo}>Accounting</Typography>
        <ul style={{ lineHeight: "2rem" }}>
          <li>
            For more than a decade, Touchstone has been a leading BPO Contact
            Center in providing call analytic services to industry leaders in
            the automotive and financial services industry.
          </li>
          <li>
            Call analytics uses technology and trained staff to analyze calls
            and customer interactions to uncover{" "}
            <Button className={classes.listButton}>read more...</Button>
          </li>
          <Button
            sx={{ marginTop: "1rem", padding: "15px 20px", fontWeight: "bold" }}
            variant="contained"
          >
            Get in Touch
          </Button>
        </ul>
      </Box>
    </Box>
  );
};

export default ContactComponentTwo;
