import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";
import DevelopmentImage from "../../assets/images/developmentImage.png";
import DevelopmentInfo from "../../components/devleopmentInfo";
import DetailsButtons from "../../components/details";
const WebDevelopment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container} py={3}>
      <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>Web Development</Typography>
        <Typography className={classes.description}>
          Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis
          tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur.
          Natoque malesuada nunc mattis tincidunt gravida aliquam.
        </Typography>
      </Box>
      <Box className={classes.imageContainer}>
        <img src={DevelopmentImage} width={"90%"} alt="" />
      </Box>
      <Box>
        <DevelopmentInfo />
      </Box>
      <Box>
        <DetailsButtons />
      </Box>
    </Box>
  );
};

export default WebDevelopment;
