import ManWithEarth from "../../assets/images/manearth.png";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
export const cardData = [
  {
    image: ManWithEarth,
    name: "Online Live Courses",
    backgroundColor: "#fff",
    description:
      "Making a real impact in Gilgit- Baltistan. Franski Enterprise is preparing the future It professionals.",
    textColor: "#000",
  },
  {
    image: OndemandVideoIcon,
    name: "Recorded Courses",
    backgroundColor: "#0202027D",
    description:
      "Working on multiple stsrtups, our leadership is striving to generate more job opportunities.",
    textColor: "#fff",
  },
  {
    image: DomainVerificationIcon,
    name: "On-Site Courses",
    backgroundColor: "#0202027D",
    description:
      "Keeping software & innovation at the forefront, we belive in technology for change.",
    textColor: "#fff",
  },
];
