import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  mainContainerbg: {
    backgroundColor: "#212626",
    padding: "0.5rem 4.6rem",
  },
  blogTitle: {
    margin: "0px",
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#ffff",
    width: "16vw",
  },
  borderDiv: {
    border: "2px solid #0790D4",
    borderRadius: "5px",
    width: "15vw",
  },
}));
