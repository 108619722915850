import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import employerImg from "../../assets/images/contact (1).png";
import ContactComponent from "../../components/contactContainerOne";
import ContactComponentTwo from "../../components/contactContainerTwo";

const ContactCenter = () => {
  const classes = useStyles();
  return (
    <Box className={classes.contactBg}>
      <Box className={classes.contactFront}>
        <Box className={classes.textButton}>
          <Typography className={classes.textBpo}>
            BPO Contact Center
          </Typography>
          <Button variant="contained" className={classes.button}>
            Get in Touch
          </Button>
        </Box>
        <Box className={classes.bpoImage}>
          <img src={employerImg} alt="human" />
        </Box>
      </Box>
      <Box padding="1rem 0rem">
        <ContactComponent />
        <ContactComponentTwo />
      </Box>
    </Box>
  );
};

export default ContactCenter;
