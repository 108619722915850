// NestedBlogComponents.js
import { Box, Button } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import React from "react";
import { useStyle } from "./style";
import blogImage1Nested from "../../../assets/images/Rectangle 1548.png";

const NestedBlogComponents = () => {
    const classes = useStyle();
    const { blogsID } = useParams();

    const location = useLocation();
    console.log(blogsID);


    console.log("this is location : :+ ", location.state); // Add this to see what state is passed


    // Retrieve the passed state from the NavLink
    const { blogContent, blogContentTwo } = location.state || {};

    // Fallback title and content if no state is passed
    const blogName = blogsID.blogName || (blogContent?.title || blogContentTwo?.title);
    const blogContentText = blogContent?.content || blogContentTwo?.content;

    return (
        <Box className={classes.mainContainerbg}>
            <Box sx={{ margin: "2rem 0rem" }}>
                <h1 className={classes.blogTitle}>Blogs Journey</h1>
                <Box className={classes.borderDiv}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Box>
                    <h2 className={classes.blogTitle2}>{blogName}</h2>
                    <p className={classes.blogTopPara}>{blogContentText}</p>
                    <p className={classes.yearTopPara}>2.3.2023 | Afaq Barcha </p>
                    <Box>
                        <Box className={classes.imageBox}>
                            <img className={classes.mainImg} src={
                                blogContent?.image
                                || blogContentTwo?.image
                                || blogImage1Nested
                            }
                                alt="relax" />
                        </Box>
                        {/* Display blog content if available */}
                        <Box>
                            {(blogContent?.title2 || blogContentTwo?.title2) && (blogContent?.content2 || blogContentTwo?.content2) ? (
                                <Box>
                                    <h2 className={classes.blogTitle2}>
                                        {blogContent?.title2 || blogContentTwo?.title2}
                                    </h2>
                                    <p className={classes.blogTopPara}>
                                        {blogContent?.content2 || blogContentTwo?.content2}
                                    </p>
                                </Box>
                            ) : (
                                <p>No content available</p>
                            )}
                        </Box>
                        <Box>
                            {(blogContent?.title3 || blogContentTwo?.title3) && (blogContent?.content3 || blogContentTwo?.content3) ? (
                                <Box>
                                    <h2 className={classes.blogTitle2}>
                                        {blogContent?.title3 || blogContentTwo?.title3}
                                    </h2>
                                    <p className={classes.blogTopPara}>
                                        {blogContent?.content3 || blogContentTwo?.content3}
                                    </p>
                                </Box>
                            ) : (
                                <p>No content available</p>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginBottom: "4rem" }}>
                <h2 className={classes.blogTitle2}>YOU MIGHT ALSO LIKE...</h2>
                <Box className={classes.firstContainer} sx={{ backgroundColor: "rgb(47, 50, 50)", width: "100%" }}>
                    <Box className={classes.blogBox}>
                        <Box className={classes.secondContainer}>
                            <img className={classes.imagesBox} src={blogImage1Nested} alt="relax" />
                            <Box className={classes.secondSubCon}>
                                <p className={classes.blogParaSC}>
                                    Discover the best practices for modern web development, from optimizing performance to enhancing user experience.                                </p>
                                <Button variant="contained">Details</Button>
                            </Box>
                        </Box>
                        <Box className={classes.secondContainer}>
                            <img className={classes.imagesBox} src={blogImage1Nested} alt="relax" />
                            <Box className={classes.secondSubCon}>
                                <p className={classes.blogParaSC}>
                                    Explore the latest trends in front-end frameworks and learn how they can streamline your development process.                                </p>
                                <Button variant="contained">Details</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};


export default NestedBlogComponents;
