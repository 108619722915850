

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/images/logo.png";

const PaymentModalDetails = ({ open, handleCloseCard }) => {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleCloseCard}
        PaperProps={{
          style: {
            width: "400px",  
            height: "66vh",  
            borderRadius: "8px",  
            padding: "0,  16px",  
            overflow: "hidden",  
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            bgcolor: "#212626",
            color: "white",
          }}
        >
          <img src={logo} alt="Logo" style={{ height: "24px" }} />
          <Typography
            variant="body1"
            sx={{ marginLeft: "8px", color: "white" }}
          >
            As a company culture
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseCard}
            sx={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{ 
            padding: "16px", 
            height: "calc(100% - 120px)" 
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New Card
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="Card Number" fullWidth variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Expiry" fullWidth variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Card Holder's Name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="CVV" fullWidth variant="outlined" />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            boxShadow: "2px 0px 25px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            $ 55
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#1565c0",
              padding: "12px 32px",
              fontSize: "16px",
            }}
          >
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PaymentModalDetails;
