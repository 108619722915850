import { Box, Typography } from "@mui/material";
import React from "react";
import imgOne from "../../assets/images/humanone (1).png";

const Card = () => {
  return (
    <Box
      sx={{ border: "3px solid #0790D3", width: "300px", borderRadius: "14px" }}
    >
      <Box>
        <img src={imgOne} alt="human" width="100%" />
      </Box>
      <Box sx={{ textAlign: "center", color: "white", padding: "4px 0px" }}>
        <Typography fontSize="22px">Lorem Ispum</Typography>
        <Typography>Lorem ipsum dolor sit amet</Typography>
      </Box>
    </Box>
  );
};

export default Card;
