import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#212626",
  },
  innerContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}));
