import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import leftArrow from "../assets/images/ArrowLeft.png";
import rightArrow from "../assets/images/Arrowright.png";

export const CarouselArrow = ({ type, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <button className={classes.leftChevron}>
        {/* <ArrowForwardIosIcon /> */}
        <img
          src={rightArrow}
          className={cn({
            [classes.arrowRight]: type === "NEXT",
          })}
          alt=""
        />
      </button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  leftChevron: {
    borderRadius: "50px",
    height: "250px",
    width: "25px",
    cursor: "pointer",
    outline: 0,
    border: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: " matrix(-1, 0, 0, 1, 0, 0)",
    background: "transparent",
    "& .MuiSvgIcon-root": {
      color: "rgba(255, 255, 255, 0.32)",
    },
    "&:hover": {
      // border: `3px solid ${theme.palette.primary.main}`,
    },
  },
  arrowRight: {
    transform: "rotate(180deg)",
    position: "relative",
  },
}));
