import { Box } from "@mui/material";
import React from "react";
import BlogDataComponent from "../../components/blogsOneContainer/index";
import { useStyle } from "./style";
import BlogTwoContainer from "../../components/blogsTwoContainer";

const BlogsComponent = ({ blogsID }) => {
  const classes = useStyle();
  return (
    <Box className={classes.mainContainerbg}>
      <Box sx={{ margin: "2rem 0rem" }}>
        <h1 className={classes.blogTitle}>Blogs Journey</h1>
        <Box className={classes.borderDiv}></Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", marginBottom: "4rem" }}>
        <BlogDataComponent blogsID={blogsID} />
        <BlogTwoContainer blogsID={blogsID} />
      </Box>
    </Box>
  );
};

export default BlogsComponent;
