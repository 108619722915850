import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "./style";
import lineImg from "../../assets/images/Rectangle 1527.png";
import AboutDataComp from "../../components/aboutComponent";
import blueLine from "../../assets/images/Rectangle 1557.png";
import Card from "../../components/aboutCards";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  return (
    <Box className={classes.aboutUs}>
      <Typography className={classes.title}>ABOUT US</Typography>
      <Box className={classes.aboutBG}>
        <Box className={classes.positionTitle}>
          <Typography className={classes.development}>
            Web Development
          </Typography>
          <img className={classes.aboutImg} src={lineImg} alt="line" />
          <Typography className={classes.typo}>
            Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc
            mattis tincidunt Lorem ipsum dolor sit amet consectetur. Natoque
            malesuada nunc mattis tincidunt Lorem ipsum dolor sit amet
            consectetur. Natoque malesuada nunc mattis tincidunt
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 0rem",
          }}
        >
          <Typography
            fontSize="20px"
            fontWeight="bold"
            color="white"
            paddingTop="2rem"
          >
            Lorem ipsum dolor sit .
          </Typography>
          <img src={blueLine} alt="blueLine" width="20%" />
        </Box>

        <AboutDataComp />
      </Box>
      <Box className={classes.secondTitle} sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "22px !important", fontWeight: "bold" }}>
          Lorem ipsum dolor sit .
        </Typography>
        <img src={blueLine} alt="" width="34%" />
        <Typography sx={{ pt: "1rem", placeSelf: "end", width: "70%" }}>
          Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis
          tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur.
          Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {" "}
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </Box>
    </Box>
  );
};

export default About;
