import React from "react";

const SatisfiedClients = () => {
  const totalClients = 2574;

  return (
    <div
      style={{
        width: "200px",
        textAlign: "center",
        color: "#FFFFFF",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <svg
        className="CircularProgressbar"
        viewBox="-10 -10 120 120"
        data-test-id="CircularProgressbar"
      >
        <path
          className="CircularProgressbar-trail"
          d="
            M 50,50
            m 0,-46
            a 46,46 0 1 1 0,92
            a 46,46 0 1 1 0,-92
          "
          strokeWidth="20"
          fillOpacity="0"
          style={{
            stroke: "rgb(230, 231, 232)",
            strokeDasharray: "289.027px, 289.027px",
            strokeDashoffset: "0px",
            strokeLinecap: "round", // Set the trail end to be round
          }}
        ></path>
        <path
          className="CircularProgressbar-path"
          d="
            M 50,50
            m 0,-46
            a 46,46 0 1 0 0,92
            a 46,46 0 1 0 0,-92
          "
          strokeWidth="20"
          fillOpacity="0"
          style={{
            stroke: "rgb(0, 174, 239)",
            strokeDasharray: "289.027px, 289.027px",
            strokeDashoffset: "115.611px",
            strokeLinecap: "round", // Set the path end to be round
          }}
        ></path>
        <text
          className="CircularProgressbar-text"
          x="50"
          y="50"
          style={{
            fill: "#FFFFFF", // Keep "Total" text white
            fontSize: "10px",
            textAnchor: "middle",
            dominantBaseline: "middle",
          }}
        >
          Total
          <tspan
            x="50"
            dy="1.2em"
            style={{ fill: "#000000", fontWeight: "bold" }} // Change number color to black
          >
            {totalClients}
          </tspan>
        </text>
      </svg>
      <p style={{ marginTop: "10px", fontSize: "22px", color: "#FFFFFF" }}>
        Satisfied clients
      </p>
    </div>
  );
};

export default SatisfiedClients;
