import { Box, Typography } from "@mui/material";
import CategoryGrid from "../categories";
import ServicesCard from "../card";
import { cardData } from "./data";

const HomeServices = () => {
  return (
    <Box>
      <Box>
        <CategoryGrid />
      </Box>
    </Box>
  );
};

export default HomeServices;

// SECOND SERVICES COMPONENT

export const OurServices = () => {
  return (
    <Box>
      <Box textAlign={"center"}>
        <Typography
          sx={{
            color: "white",
            fontSize: "40px",
            fontWeight: 800,
          }}
        >
          Our Services
        </Typography>
        <Typography sx={{ color: "white", fontSize: "14px", fontWeight: 800 }}>
          STARTUPS BY FRANSKI ENTERPRISE
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }} py={3}>
        {cardData.map((items) => {
          return <ServicesCard {...items} />;
        })}
      </Box>
    </Box>
  );
};
