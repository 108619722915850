import { Box } from "@mui/material";
import { useStyles } from "./style";
import PaymentDetails from "../../components/paymentDetails";
import PaymentEntry from "../../components/paymentEntry";

const Payment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <PaymentDetails />
      </Box>

      <Box sx={{ padding: 2 }}>
        <PaymentEntry />
      </Box>
    </Box>
  );
};

export default Payment;
