import React from "react";
import Button from "../../components/button";
import { Box, Typography } from "@mui/material";
import { useStyle } from "./style";

const GisPage = () => {
  const classes = useStyle();
  return (
    <Box className={classes.gisBackground}>
      <form className={classes.formStyle}>
        <input
          type="text"
          className={classes.gisInput}
          placeholder="Lorem ipsum dolor sit amet consectetur. Natoque"
        />
        <input
          type="text"
          className={classes.gisInput}
          placeholder="Lorem ipsum dolor sit amet consectetur. Natoque"
        />
        <input
          type="text"
          className={classes.gisInput}
          placeholder="Lorem ipsum dolor sit amet consectetur. Natoque"
        />
        <input
          type="text"
          className={classes.gisInput}
          placeholder="Lorem ipsum dolor sit amet consectetur. Natoque"
        />

        <textarea className={classes.gisTextArea} />
        <Box className={classes.bottomBox}>
          <Typography sx={{ gridColumn: "1/5", color: "white" }}>
            Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc
            mattis tincidunt gravida aliquam.Lorem ipsum dolor sit amet
            consectetur. Natoque malesuada nunc mattis tincidunt gravida
          </Typography>
          <Box sx={{ gridColumn: "5/7", textAlign: "end" }}>
            <Button variant="contained" className={classes.gisButton}>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default GisPage;
