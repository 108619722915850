import { Box, Typography } from "@mui/material";
import Button from "../button";

const DetailsButtons = () => {
  return (
    <Box pb={4}>
      <Box py={3}>
        <Typography
          sx={{
            fontSize: "38px",
            fontWeight: 700,
            fontFamily: "Poppins",
            color: "white",
            textAlign: "center",
          }}
        >
          More things we do
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 5,
          padding: "0px 60px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 15 }}>
          {" "}
          <Button
            sx={{
              background: "#009696",
              padding: "20px 80px",

              "&:hover:": {
                background: "#009696",
              },
            }}
          >
            Graphic Design
          </Button>
          <Button
            sx={{
              background: "#009696",
              padding: "20px 80px",
              "&:hover:": {
                background: "#009696",
              },
            }}
          >
            UX/UI Design
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 15 }}>
          {" "}
          <Button
            sx={{
              background: "#009696",
              padding: "20px 80px",
              "&:hover:": {
                background: "#009696",
              },
            }}
          >
            Graphic Design
          </Button>
          <Button
            sx={{
              background: "#009696",
              padding: "20px 80px",
              "&:hover:": {
                background: "#009696",
              },
            }}
          >
            UX/UI Design
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsButtons;
