import { Box, Typography } from "@mui/material";
import React from "react";
// import productImage from "../../assets/images/proPic.png";
import webDevelopmentImage from "../../assets/images/webdevelopment.png";
import graphicImage from "../../assets/images/graphic.png";
const ProductData = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        justifyContent: "center",
        color: "white",
      }}
    >
      <Box sx={{ height: "70vh", width: "40vw" }}>
        <img
          src={webDevelopmentImage}
          alt="productImg"
          width="100%"
          height="100%"
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.4rem" }}>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          Web Development
        </Typography>
        <Typography>
          Web development involves creating and maintaining websites and web
          applications. It encompasses various aspects, from front-end design
          and user experience to back-end programming and database management.
          As the digital landscape continues to evolve, web development remains
          a critical skill for building functional, accessible, and engaging
          online platforms. HTML Hypertext Markup Language: The standard
          language for creating web pages and web applications. CSS Cascading
          Style Sheets: Used to style and layout web pages, controlling the
          visual presentation. JavaScript: A programming language that enables
          interactive elements and dynamic content on web pages.
        </Typography>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "1.4rem" }}
        pt={5}
      >
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          Web Development
        </Typography>
        <Typography>
          Web development involves creating and maintaining websites and web
          applications. It encompasses various aspects, from front-end design
          and user experience to back-end programming and database management.
          As the digital landscape continues to evolve, web development remains
          a critical skill for building functional, accessible, and engaging
          online platforms. HTML Hypertext Markup Language: The standard
          language for creating web pages and web applications. CSS Cascading
          Style Sheets: Used to style and layout web pages, controlling the
          visual presentation. JavaScript: A programming language that enables
          interactive elements and dynamic content on web pages.
        </Typography>
      </Box>
      <Box sx={{ height: "70vh", width: "40vw" }} pt={5}>
        <img src={graphicImage} alt="productImg" width="100%" height="100%" />
      </Box>
    </Box>
  );
};

export default ProductData;
