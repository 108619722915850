import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  containerBg: {
    backgroundColor: "#2F3232",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",

    justifyContent: "center",

    padding: "1.4rem",
    gap: "1rem",
    color: "white",
    borderRadius: "15px",
  },
  imageBox: {
    height: "70vh",
  },
  buttonBox: {
    textAlign: "left",
    width: "95%",
    padding: "10px 0px",
  },
}));
