import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#212626",
  },
  heading: {
    color: "#fff",
    fontWeight: 700 + "!important",
    fontSize: "40px !important",
    borderBottom: "5px solid #0790D4",
    // width: "25%",
  },
  description: {
    fontWeight: 400 + "!important",
    fontSize: "18px !important",
    color: "#fff",
    fontFamily: "Poppins !important",
    padding: "20px 0px !important",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 150px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));
