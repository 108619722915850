import { Box, Typography } from "@mui/material";
import React from "react";
import aboutusImg from "../../assets/images/Rectangle 1556.png";
const AboutDataComp = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        justifyContent: "center",
        paddingTop: "1.5rem",
        gap: "1rem",
        color: "white",
      }}
    >
      <Box sx={{ height: "75vh" }}>
        <img src={aboutusImg} alt="relax" width="95%" height="98%" />
      </Box>
      <Box>
        <Typography>
          {" "}
          Lorem ipsum dolor sit amet consectetur. Natoque malesuada nunc mattis
          tincidunt gravida aliquam.Lorem ipsum dolor sit amet consectetur.
          Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum
          dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt
          gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque
          malesuada nunc mattis tincidunt gravida aliquam. Lorem ipsum dolor sit
          amet consectetur. NatoquLorem ipsum dolor sit amet consectetur.
          Natoque malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum
          dolor sit amet consectetur. Natoque malesuada nunc mattis tincidunt
          gravida aliquam.Lorem ipsum dolor sit amet consectetur. Natoque
          malesuada nunc mattis tincidunt gravida aliquam.Lorem ipsum dolor sit
          amet consectetur. Natoque malesuada nunc mattis tincidunt gravida
          aliquam. Lorem ipsum dolor sit amet consectetur. Natoqu
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutDataComp;
